import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldPhoneNumberInput, NamedLink } from '../../../components';

import css from './SignupForm.module.css';

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        isdecrypted,
        values,
        form, 
        termsAndConditions,
      } = fieldRenderProps;

      const [password, setPassword] = useState('');
      const [isValid, setIsValid] = useState({
        uppercase: false,
        lowercase: false,
        specialChar: false,
        number: false,
        length: false,
      });
      const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        form.change('password', newPassword)

        validatePassword(newPassword);
      };
      const validatePassword = (password) => {
        const uppercaseRegex = /[A-Z]/;
        const lowercaseRegex = /[a-z]/;
        const specialCharRegex = /[^A-Za-z0-9]/;
        const numberRegex = /\d/;

        setIsValid({
          uppercase: uppercaseRegex.test(password),
          lowercase: lowercaseRegex.test(password),
          specialChar: specialCharRegex.test(password),
          number: numberRegex.test(password),
          length: password.length >= 8,
        });
      };


      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );
      const phonePlaceholder = intl.formatMessage({
        id: 'ContactDetailsForm.phonePlaceholder',
      });
      const phoneLabel = intl.formatMessage({ id: 'ContactDetailsForm.phoneLabel' });
      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !values.password || !isValid.length || !isValid.lowercase
        || !isValid.number || !isValid.number || !isValid.uppercase;
      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPass" />
        </NamedLink>
      );

      const loginLink = (
        <NamedLink name="LoginPage" className={css.recoveryLink}>
          <FormattedMessage id="SignupForm.loginText" />
        </NamedLink>
      )
      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              // label={intl.formatMessage({
              //   id: 'SignupForm.emailLabel',
              // })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.emailPlaceholders',
              })}
              validate={validators.composeValidators(emailRequired, emailValid)}
              disabled={isdecrypted ? true : false}
              className={css.fieldInput}
            />

            {
              isdecrypted ? <>
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.Restaurant',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.resturantPlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.resturantRequired',
                    })
                  )}
                  className={css.fieldInput}
                />
                <FieldTextInput
                  type="url"
                  id={formId ? `${formId}.website` : 'website'}
                  name="website"
                  autoComplete="website"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.website',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.websitePlaceholder',
                  })}
                  className={css.fieldInput}
                />
                <FieldTextInput
                  type="text"
                  id={formId ? `${formId}.restaturantgroup` : 'restaturantgroup'}
                  name="restaturantgroup"
                  autoComplete="restaturantgroup"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.Restaurants Within Group',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.RestaurantsPlaceholder',
                  })}
                  className={css.fieldInput}
                />

                <FieldPhoneNumberInput
                  type="number"
                  id={formId ? `${formId}.phonenumber` : 'phonenumber'}
                  name="phonenumber"
                  autoComplete="phonenumber"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.phone',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.phonePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.phoneRequired',
                    })
                  )}
                  className={css.fieldInput}
                />

                <FieldTextInput
                  className={css.fieldInput}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="role"
                  // label={intl.formatMessage({
                  //   id: 'SignupForm.role',
                  // })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.rolePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.roleRequired',
                    })
                  )}

                />

              </> :
                <div className={css.inputRow}>
                  <FieldTextInput
                    className={css.inputBox}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="fname"
                    autoComplete="given-name"
                    // label={intl.formatMessage({
                    //   id: 'SignupForm.firstNameLabel',
                    // })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.firstNamePlaceholders',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.firstNameRequired',
                      })
                    )}
                  />
                  <FieldTextInput
                    className={css.inputBox}
                    type="text"
                    id={formId ? `${formId}.lname` : 'lname'}
                    name="lname"
                    autoComplete="family-name"
                    // label={intl.formatMessage({
                    //   id: 'SignupForm.lastNameLabel',
                    // })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.lastNamePlaceholders',
                    })}
                    validate={validators.required(
                      intl.formatMessage({
                        id: 'SignupForm.lastNameRequired',
                      })
                    )}
                  />
                </div>
            }


            {/* <FieldTextInput
              className={css.fieldInput}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              // label={intl.formatMessage({
              //   id: 'SignupForm.passwordLabel',
              // })}
              placeholder={intl.formatMessage({
                id: 'SignupForm.passwordPlaceholders',
              })}
              validate={passwordValidators}
            />  */}
            <div className={css.fieldInput}>
              <input
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                values="password"
                autoComplete="new-password"
                placeholder="Enter your password"
                onChange={(e) => handlePasswordChange(e)}
                validate={passwordValidators}
              />
            </div>

            {/* <FieldTextInput
              type="password"
              id="password"
              name="password"
              autoComplete="new-password"
              placeholder="Enter your password"
              onChange={handlePasswordChange}
            /> */}

          </div>
          {/* {termsAndConditions} */}
          <div className={css.conditionSignupCheckbox}>
            <div className={css.checkSignup}>
              <fieldset>
                <div className={css.checkboxesContainer}>
                  <label for="checkbox1" className={css.option}>one lowercase character
                    <input type="checkbox" id="checkbox1" name="lowercaseCharacter" checked={isValid.lowercase} disabled aria-checked="false" />
                    <span className={css.checkbox1}></span>
                  </label>
                  <label for="checkbox2" className={css.option}>one special character
                    <input type="checkbox" id="checkbox2" checked={isValid.specialChar} name="specialCharacter" aria-checked="false" />
                    <span className={css.checkbox1}></span>
                  </label>
                  <label for="checkbox3" className={css.option}>one uppercase character
                    <input type="checkbox" id="checkbox3" checked={isValid.uppercase} name="uppercaseCharacter" aria-checked="false" />
                    <span className={css.checkbox1}></span>
                  </label>
                  <label for="checkbox4" className={css.option}>8 character minimum
                    <input type="checkbox" id="checkbox4" checked={isValid.length} name="characterMinimum" aria-checked="false" />
                    <span className={css.checkbox1}></span>
                  </label>
                  <label for="checkbox5" className={css.option}>one number
                    <input type="checkbox" id="checkbox5" checked={isValid.number} name="oneNumber" aria-checked="false" />
                    <span className={css.checkbox1}></span>
                  </label>
                </div>
              </fieldset>
            </div>
          </div>
          <div className={css.bottomWrapper}>
            <PrimaryButton className={css.submitButton} type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signup" />
            </PrimaryButton>
          </div>
          <p className={css.alreadyMember}>
            <FormattedMessage
              id="SignupForm.alreadyHaveAccount"
              values={{ loginLink }}
            />
          </p>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
