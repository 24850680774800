import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, NamedLink, FieldSelect, FieldCheckbox } from '../../../components';

import css from './LoginForm.module.css';

const LoginFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        intl,
        invalid,
        values
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'LoginForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'LoginForm.emailPlaceholders',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'LoginForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'LoginForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'LoginForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'LoginForm.passwordPlaceholde',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'LoginForm.passwordRequired',
      });
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress || !values.email || !values.password;

      const passwordRecoveryLink = (
        <NamedLink name="PasswordRecoveryPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.forgotPass" />
        </NamedLink>
      );

      const [validEmailMessage, setValidEmailMessage] = useState();

      const handleValues = () => {
        const validEmail = validators.EMAIL_RE.test(values.email) ? true : false;
        if (validEmail) {
          handleSubmit(values)
        }
        else {
          setValidEmailMessage("Enter valid email address")
        }
      }

      const createAccountLink = (
        <NamedLink name="SignupPage" className={css.recoveryLink}>
          <FormattedMessage id="LoginForm.createAccount" />
        </NamedLink>
      )

      return (
        <Form className={classes} >
          {validEmailMessage && <div className={css.validEmailError}>{validEmailMessage}</div>}
          <div>
            <FieldTextInput
              className={css.inputBox}
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              // label={emailLabel}
              placeholder={emailPlaceholder}
            />
            <FieldTextInput
              className={css.inputBox}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="current-password"
              // label={passwordLabel}
              placeholder={passwordPlaceholder}
            // validate={passwordRequired}
            />
          </div>
          <div className={css.bottomWrapper}>
            <div className={css.checkGrid}>
              <div className={css.rememberMe}>
                <input type='checkbox' />
                <FieldCheckbox
                  className={css.saveForLaterUseCheckbox}
                  textClassName={css.checkLabel}
                  label="Remember me"
                  id="saveAfterOnetimePayment"
                  name="saveAfterOnetimePayment"
                  value="saveAfterOnetimePayment"
                />
              </div>
              <p className={css.bottomWrapperText}>
                <FormattedMessage
                  id="LoginForm.forgotPasswordInf"
                  values={{ passwordRecoveryLink }}
                />
              </p>
            </div>
            <PrimaryButton
              className={css.submitButton}
              type="button"
              onClick={() => handleValues()}
              inProgress={submitInProgress}
              disabled={submitDisabled}
            >
              <FormattedMessage id="LoginForm.logIns" />
            </PrimaryButton>
            <p className={css.newMember}>
              <FormattedMessage
                id="LoginForm.newMembers"
                values={{ createAccountLink }}
              />
            </p>
          </div>
        </Form>
      );
    }}
  />
);

LoginFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  form: null,
  inProgress: false,
};

const { string, bool } = PropTypes;

LoginFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  form: string,
  inProgress: bool,
  intl: intlShape.isRequired,
};

const LoginForm = compose(injectIntl)(LoginFormComponent);
LoginForm.displayName = 'LoginForm';

export default LoginForm;
